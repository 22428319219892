<script setup lang="ts">
const head = useLocaleHead({
	addDirAttribute: true,
	identifierAttribute: 'id',
	addSeoAttributes: true,
})
</script>
<template>
	<Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
		<Body class="bg-white dark:bg-slate-900">
			<div class="relative min-h-screen flex flex-col">
				<main class="container mx-auto flex-grow pb-8 pt-28 text-gray-800 dark:text-white">
					<slot />
				</main>
			</div>
		</Body>
	</Html>
</template>
